<template>
  <div>
    <div v-if="role==='Agent' && levelOptions.length > 1" class="row">
      <div class="col-md-4">
        <b-form-group
          label="ระดับเอเย่นต์"
        >
          <b-form-select
            v-model="data.account.levelId"
            :options="levelOptions"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <b-form-group
          label="ชื่อผู้ใช้ *"
          description="ความยาว 2-20 ตัวอักษร ภาษาอังกฤษ a-Z หรือตัวเลข 0-9 เท่านั้น"
          :state="invalidKeys.username.status"
          :invalid-feedback="invalidKeys.username.text"
        >
          <b-input-group
            :prepend="user.prepend"
          >
            <b-form-input
              v-model="user.username"
              type="text"
              placeholder="ชื่อผู้ใช้"
              required
              :state="invalidKeys.username.status"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-md-4">
        <b-form-group
          size="sm"
          label="รหัสผ่าน *"
          description="รหัสผ่านอย่างน้อย 6 ตัวอักษร"
          :state="invalidKeys.password.status"
          :invalid-feedback="invalidKeys.password.text"
        >
          <b-form-input
            v-model="data.user.password"
            type="password"
            placeholder="รหัสผ่าน"
            minLength="6"
            required
            :state="invalidKeys.password.status"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-4">
        <b-form-group
          label="โทรศัพท์"
        >
          <b-form-input
            v-model="data.user.tel"
            type="text"
            placeholder="โทรศัพท์"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <b-form-group
          label="ชื่อ *"
          :state="invalidKeys.firstName.status"
          :invalid-feedback="invalidKeys.firstName.text"
        >
          <b-form-input
            v-model="data.user.firstName"
            type="text"
            placeholder="ชื่อ"
            required
            :state="invalidKeys.firstName.status"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-4">
        <b-form-group
          label="นามสกุล"
        >
          <b-form-input
            v-model="data.user.lastName"
            type="text"
            placeholder="นามสกุล"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-4">
        <b-form-group
          label="Line ID"
        >
          <b-form-input
            v-model="data.user.lineId"
            type="text"
            placeholder="Line ID"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

  </div>
</template>
<script>
import SystemConfigService from '@/services/SystemConfigService'

export default {
  name: 'NewAccountProfileForm',
  props: ['data', 'user', 'invalidKeys', 'role'],
  computed: {
    agentLevels() {
      return this.$store.state.accountLevels
    },
    levelOptions() {
      return this.agentLevels.map((l)=>{
        return {
          value: l._id,
          text: l.levelName
        }
      })
    }
  },
  watch: {
    agentLevels() {
      if(this.agentLevels.length > 0) {
        this.data.account.levelId = this.agentLevels[0]._id
      }
    }
  },
  mounted() {
    if(this.agentLevels.length > 0) {
      this.data.account.levelId = this.agentLevels[0]._id
    }
  }
}
</script>
