<template>
  <div class="py-2 mt-3">
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th width="40%">กลุ่ม</th>
          <th class="text-center" width="15%">ได้รับ</th>
          <th width="15%" class="text-center">แบ่งหุ้น(ให้เอเย่นต์)</th>
          <th class="text-center" width="15%">เก็บของ</th>
          <th width="15%" class="text-center">เก็บของเกิน</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="group in data" v-if="group.isAvailable">
          <td>{{ groupName(group.groupId) }}</td>
          <td class="text-center">
            {{ shareReceive(group) }}%
          </td>
          <td class="text-center">
            <b-form-select
              v-model="group.share.receive"
              :options="shareOptions(group)"
              size="sm"
            ></b-form-select>
          </td>
          <td class="text-center">
            {{ keepValue(group) }}%
          </td>
          <td class="text-center">
            <b-form-checkbox v-model="group.share.over" name="check-button" :class="[group.share.over ? 'text-success' : 'text-danger']" switch> <span class="small">{{ group.share.over ? 'เก็บของเกิน' : 'ไม่เก็บของเกิน' }}</span> </b-form-checkbox>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'NewAccountSettingShare',
  props: ['data', 'refSetting', 'role'],
  methods: {
    groupName(groupId) {
      let group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group.groupTitle
      }else{
        return groupId
      }
    },
    shareOptions(group) {
      const maxShare = parseInt(this.refSetting[group.groupId].share?.receive)
      let sharePercents = []
      for(let i=maxShare; i>=0; i-=0.5) {
        sharePercents.push({
          text: i.toFixed(1),
          value: i
        })
      }
      return sharePercents
    },
    keepValue(group) {

      const receive = this.refSetting[group.groupId].share?.receive
      group.share.referral = receive - group.share.receive

      return group.share.referral.toFixed(1)
    },
    shareReceive(group) {
      return this.refSetting[group.groupId].share?.receive?.toFixed(1)
    }
  },
}
</script>
<style lang="scss" scoped>
.table {
  td {
    vertical-align: middle;
  }
}
</style>
